import { motion } from "framer-motion";
import { Link } from "gatsby";
import React from "react";
import { Col, Grid, Row } from "react-flexbox-grid";

import { ArrowIcon } from "../Icons";
import FacebookIcon from "./facebook";
import InstagramIcon from "./instagram";
import LinkedinIcon from "./linkedin";
import * as styles from "./styles.module.css";
import TwitterIcon from "./twitter";
import YoutubeIcon from "./youtube";

const SocialIcon = ({ href, icon, label }) => (
  <motion.a
    whileHover={{ scale: 1.2, y: -5 }}
    target="_blank"
    rel="noreferrer"
    href={href}
    className={styles.socialIcon}
    aria-label={label}
  >
    {icon}
  </motion.a>
);

const Footer = () => (
  <footer className={styles.container}>
    <div className={styles.goToTop}>
      <motion.button
        href="#main"
        whileHover="active"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <motion.span variants={{ active: { scale: 1.2, y: -10 } }}>
          <ArrowIcon />
        </motion.span>
        <span>TOP</span>
      </motion.button>
    </div>
    <Grid>
      <Row className={styles.footerTop}>
        <Col xs={12} md={4}>
          <img
            src="https://dudbm6bcnmy8e.cloudfront.net/cwru/img/bicentennial-logo-horizontal-white.png"
            alt="Case Western Reserve University Bicentennial Logo"
            className={`${styles.logo} mb2`}
            title="Case Western Reserve University Bicentennial"
          />
        </Col>
        <Col xs={12} md={8}>
          <Row center="xs" end="md" className={styles.socialRow}>
            <SocialIcon
              href="https://www.facebook.com/casewesternreserve"
              icon={<FacebookIcon />}
              label="Facebook Icon"
            />
            <SocialIcon
              href="https://instagram.com/cwru/"
              icon={<InstagramIcon />}
              label="Instagram Icon"
            />
            <SocialIcon
              href="https://twitter.com/cwru"
              icon={<TwitterIcon />}
              label="Twitter Icon"
            />
            <SocialIcon
              href="https://www.youtube.com/user/case"
              icon={<YoutubeIcon />}
              label="YouTube Icon"
            />
            <SocialIcon
              href="https://www.linkedin.com/school/case-western-reserve-university/"
              icon={<LinkedinIcon />}
              label="LinkedIn Icon"
            />
            <div className={styles.line} />
          </Row>
        </Col>
      </Row>
      <Row between="xs">
        <Col xs={12} md={4} className="vertical-between mb1">
          <div>
            <address>10900 Euclid Ave., Cleveland, OH 44106</address>
            <a href="tel:12163682000">216.368.2000</a>
          </div>
          <div>
            <div className="mb1">
              © {new Date().getFullYear()} Case Western Reserve University
            </div>
            <div className="mb04">
              <a href="https://case.edu/utech/policies/legal-privacy-notice/">
                Legal Notice
              </a>
              &nbsp;|&nbsp;
              <Link to="/privacy-statement">Privacy Statement</Link>
            </div>
            <div>
              <a
                href="https://case.edu/equity/sexual-harassment-title-ix/title-ix-notice-nondiscrimination"
                className="mt1"
              >
                Title IX Notice of Non-Discrimination
              </a>
            </div>
          </div>
        </Col>
        <Col xs={12} md={2}>
          <FooterLinks links={Menu1} />
        </Col>
        <Col xs={12} md={2}>
          <FooterLinks links={Menu2} />
        </Col>
        <Col xs={12} md={3}>
          <FooterLinks links={Menu3} />
        </Col>
      </Row>
    </Grid>
  </footer>
);

const FooterLinks = ({ links }) => (
  <div className={styles.footerLinks}>
    {links.map((link) => (
      <a href={link.to} key={link.name}>
        {link.name}
      </a>
    ))}
  </div>
);

export const Menu1 = [
  { name: "Campus Safety", to: "https://case.edu/publicsafety/" },
  { name: "Canvas", to: "https://canvas.case.edu/" },
  { name: "Careers", to: "https://case.edu/hr/careers" },
  { name: "Diversity", to: "https://case.edu/diversity/" },
  { name: "Directory", to: "https://webapps.case.edu/directory/" },
];

export const Menu2 = [
  { name: "Equity", to: "https://case.edu/equity/" },
  { name: "Financial Aid", to: "https://case.edu/financialaid/" },
  { name: "Give to CWRU", to: "https://case.edu/give/" },
  { name: "Libraries", to: "https://case.edu/campus-life/libraries/" },
];

export const Menu3 = [
  { name: "Media", to: "https://case.edu/umc/about-us/contact-our-team" },
  { name: "Student Information System", to: "https://case.edu/sis" },
  { name: "University Technology", to: "https://case.edu/utech/" },
  { name: "Webmail", to: "https://mail.google.com/a/case.edu/" },
];

export default Footer;
